export default class DfShoppingProduct {
  private _description: string;
  private _checked: boolean;


  constructor(description: string, checked: boolean) {
    this._description = description;
    this._checked = checked;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  get checked(): boolean {
    return this._checked;
  }

  set checked(checked: boolean) {
    this._checked = checked;
  }
}
